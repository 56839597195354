<template>
	<div class="h-screen flex w-full bg-img">
		<div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
			<vx-card>
				<div slot="no-body" class="full-page-bg-color">
					<div class="vx-row">
						<div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
							<img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto" />
						</div>
						<div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
							<div class="p-8">
								<div class="vx-card__title mb-8">
									<h4 class="mb-4">Reset Password</h4>
									<p>Please enter your new password.</p>
								</div>

								<vs-input v-filter-input
									type="email"
									class="w-full mb-6"
									label-placeholder="Email"
									v-model="email"
									readonly />

								<vs-input
									type="password"
									class="w-full mb-6"
									name="password"
									:label-placeholder="$t('Password')"
									v-model="password"
									v-validate="'required|min:8'"
								/>
								<span
									class="text-danger text-sm"
								>{{ $t(errors.first('password')) }}</span>

								<vs-input
									type="password"
									class="w-full mb-6"
									name="confirm-password"
									:label-placeholder="$t('Confirm Password')"
									v-model="passwordConfirmation"
									v-validate="'required|min:8'"
								/>
								<span
									class="text-danger text-sm"
								>{{ $t(errors.first('confirm-password')) }}</span>

								<div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
									<vs-button
										type="border"
										to="/pages/login"
										class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
									>{{ $t('Go Back To Login') }}</vs-button>

									<vs-button
										class="w-full sm:w-auto"
										:class="{ 'is-loading': isResetting }"
										:disabled="!isFormValid"
										@click="resetPassword">
										{{ $t('Reset') }}
									</vs-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
import authApi from '../../api/auth';
import { getAxiosErrorMessage } from '../../lib/helper';

export default {
  data() {
    return {
      // email: '',
      password: '',
      passwordConfirmation: '',
      isResetting: false,
    };
  },
  methods: {
    resetPassword() {
      this.isResetting = true;
      const params = {
        email: this.email,
        token: this.token,
        new_password: this.password,
        confirm_password: this.passwordConfirmation,
      };
      const callback = (response) => {
        const message = response.message;
        this.$vs.notify({
          title: 'Reset Password',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isResetting = false;
        this.$router.push('/login');
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: 'Reset Password',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isResetting = false;
      };
      authApi.resetPassword(params, callback, errorCallback);
    },
  },
  computed: {
    isFormValid() {
      return this.password !== '' && this.passwordConfirmation !== '' && this.password === this.passwordConfirmation;
    },
    email() {
      return this.$route.query.email || '';
    },
    token() {
      return this.$route.query.token || '';
    },
    lang() {
      return this.$route.query.lang || 'en';
    },
  },
};
</script>
